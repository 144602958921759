.f2x-contact-btn-spinner {
    -webkit-animation: f2x-icon-spin 2s infinite linear;
    animation: f2x-icon-spin 2s infinite linear;
}

.f2x-step-item > h4 {
    z-index: -1;
}

.f2x-app-headline.f2x-hidden {
    display: none;
}

@-webkit-keyframes f2x-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes f2x-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@media only screen and (min-width: 1200px) {
    .f2x-ooh-app-wrap {
        max-width: 80% !important;
    }
}

.f2x-ooh-app-wrap {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;

    .f2x-form {
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
    }
    .f2x-steps {
        flex: 0 0 100%;
    }

    .f2x-form {
        flex: 0 0 100%;
    }

    .f2x-hidden {
        display: none;
    }

    .f2x-visible {
        
    }
    .f2x-row {
        display: block;
        margin-bottom: 24px;
        @media screen and (min-width: 1024px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px !important;
            margin-bottom: 0;
        }
    }

    .f2x-form-btn {
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        border-bottom-width: 0;
        border-color: #0091cd;
        background-color: #0091cd;
        color: #fff;
        font-family: "Houschka-Rounded", houschka-rounded, sans-serif;
        font-weight: 700;
        line-height: 1;
        font-size: 20px;
        font-size: 1.3333333333333rem;
        border-radius: 12px;
        padding-top: 18px;
        padding-right: 50px;
        padding-bottom: 18px;
        padding-left: 50px;
        margin-top: 24px;
        display: flex;

        cursor: pointer;
        span {
            display: inline-block;
            &.f2x-contact-btn-spinner-wrap {
                margin-right: 8px;
            }
        }
        &:hover,
        &:focus {
            color: #fff;
            background-color: darken($color: #0091cd, $amount: 10%);
            border-color: darken($color: #0091cd, $amount: 10%);
        }

        &:disabled {
            cursor: not-allowed;
            color: darken($color: #fff, $amount: 20%);
            background-color: darken($color: #0091cd, $amount: 20%);
            border-color: darken($color: #0091cd, $amount: 20%);
        }
    }


    .f2x-react-select-container {
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
        border-style: solid;
        border-width: 0;
        border-bottom-color: #fff;
        border-bottom-width: 1px;
        width: 75%;
    
        .f2x-react-select {
            background-color: transparent;
            border: none;
            padding: 2px 0px;
            font-weight: 400;
            .react-select__single-value {
                color: #fff;
            }
            .react-select__indicators {
                padding-left: 8px;
            }
    
            &.react-select__menu {
                background-color: #2d2d32 !important;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                margin-top: 1px;
            }
    
            &.react-select__menu-list {
                max-height: 280px;
            }
    
            &.react-select__option {
                color: #fff;
                padding: 6px 12px;
                cursor: pointer;
    
                line-height: 1.4em !important;
                &:hover {
                    color: #0091cd;
                }
            }
    
            &.react-select__placeholder {
                color: #fff;
            }
    
            &.react-select__option--is-disabled {
                color: rgba($color: #fff, $alpha: 0.5);
                cursor: not-allowed;
                &:hover {
                    color: rgba($color: #fff, $alpha: 0.5);
                }
            }
        }
    
        .css-t3ipsp-control {
            box-shadow: none !important;
            border: none !important;
            &:hover {
                border-color: transparent !important;
            }
        }
    }
    
    .f2x-ooh-contact-app-form-wrap {
        border-radius: 12px;
        border: 2px solid #707070;
        padding: 1rem;
    
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border-bottom: 1px solid #fff;
            -webkit-text-fill-color: #fff;
            -webkit-box-shadow: 0 0 0px 1000px #2d2d32 inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    
        .f2x-contact-app-form-group {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
    
            label {
                display: inline-block;
                margin-bottom: 1px;
                margin-top: 5px;
            }
    
            input[type="text"],
            input[type="tel"],
            input[type="email"],
            select {
                position: relative;
                display: inline-block;
                &,
                &:focus {
                    outline: none;
                    border: none;
                    border-bottom: 1px solid #fff;
                    background-color: transparent;
                    color: #fff;
                }
            }
            input[type="text"],
            input[type="tel"],
            input[type="email"] {
                padding: 0.2rem 0rem 0.5rem 0rem;
            }
    
            select {
                appearance: none !important;
                background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
                    linear-gradient(135deg, #fff 50%, transparent 50%);
    
                background-position: calc(100% - 20px) calc(1em + 2px),
                    calc(100% - 15px) calc(1em + 2px);
                background-size: 5px 5px, 5px 5px;
                background-repeat: no-repeat;
            }
        }
    
        .f2x-contact-app-form-info {
            text-align: right;
            font-weight: 400;
            flex: 0 0 100%;
        }
    
        .f2x-contact-app-form-group-checkbox {
            margin-bottom: 1rem;
            margin-top: 1rem;
    
            label {
                margin-bottom: 0;
                font-family: "Museo-Sans", museo-sans, sans-serif;
                color: #fff;
                font-size: 0.75rem;
                font-weight: 400;
                display: flex;
                //align-items: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                }
                a {
                    color: #fff !important;
                }
                span {
                    &.f2x-contact-app-form-group-checkbox-check {
                        width: 25px;
                        height: 25px;
                        border: 2px solid #707070;
                        display: inline-block;
                        margin-right: 8px;
                        border-radius: 4px;
                        flex: 0 0 25px;
                    }
                }
                input[type="checkbox"]:checked
                    + span.f2x-contact-app-form-group-checkbox-check {
                    border-color: #198754;
                    position: relative;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                }
            }
        }
    
        .f2x-react-select-container {
            width: auto;
        }
    }

    .f2x-ooh-contact-thanks{
        padding-top: 1rem;
    }
    
    .f2x-contact-app-form-control-feedback,
    .f2x-contact-app-form-general-feedback {
        color: #dc3545;
        margin-top: 0.5rem;
        font-weight: 400;
    }

    .f2x-contact-app-form[data-validated="true"],
    .f2x-contact-app-form[data-has-errors="true"] {
        .f2x-contact-app-form-control {
            &.is-valid {
                border-color: #198754;
            }
    
            &.is-invalid {
                border-color: #dc3545;
            }
        }
    }

    .f2x-contact-app-form-submit {
        text-align: center;
    }
    .f2x-form .f2x-form-btn {
        display: inline-flex;
    }
}

@media screen and (min-width: 768px) {
    .f2x-ooh-contact-app-form-wrap {
        .f2x-contact-app-form-group-checkbox {
            margin-left: 8px;
            label {
                font-size: 1rem;
            }
        }
    }
    .f2x-ooh-contact-app-form-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;

        .f2x-contact-app-form-group {
            flex: 0 0 100%;
            padding-left: 8px;
            padding-right: 8px;
            display: inline-flex;

            &[data-field="firstname"] {
                flex: 0 0 50%;
            }

            &[data-field="lastname"] {
                flex: 0 0 50%;
            }
            &[data-field="phone"] {
                flex: 0 0 50%;
            }

            &[data-field="email"] {
                flex: 0 0 50%;
            }
        }
    }
}
